<template>
  <div>
    <div>
      <template v-if="item.Translate || item.Translate == undefined">
        <v-select
          :items="itemLanguages"
          v-model="itemDefaultLanguage"
          single-line
          class="pageElementLanguageSelectRichText"
        ></v-select>
      </template>

      <template v-for="(translatedValue, translatedValueIndex) in item.Value">
        <template
          v-if="
            itemDefaultLanguage == translatedValueIndex &&
            ((!item.ReadOnly && !needsDisable) || editItemAdmin)
          "
        >
          <editor
            api-key="f18i0ywzo341k9tx3r3vjsnoghnomdrqbhx2qqi5lnul1dbh"
            v-model="item.Value[translatedValueIndex]"
            :key="tinyId + translatedValueIndex"
            :required="item.Required"
            :init="{
              height: 500,
              menubar: false,
              language: editorLanguage,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic underline strikethrough | table image link | \
                         alignleft aligncenter alignright alignjustify | \
                         bullist numlist outdent indent | removeformat',
            }"
            :disabled="
              $helpers.itemNeedsDisable(
                item.ReadOnly,
                needsDisable,
                editItemAdmin
              )
            "
          />
        </template>
        <p
          v-html="item.Value[translatedValueIndex]"
          class="mb-0 text-field-item"
          v-if="
             $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin) &&
            itemDefaultLanguage == translatedValueIndex
          "
          :key="tinyId + translatedValueIndex"
        ></p>
      </template>
    </div>

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col cols="12">
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              dense
              :label="$t('Column width')"
              class="mw-w"
            ></v-text-field>
            <v-switch
              v-model="item.Translate"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Translate item')"
            ></v-switch>
            <v-switch
              v-model="item.Required"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Field is required')"
            ></v-switch>
            <v-switch
              v-model="item.ReadOnly"
              hide-details="auto"
              :label="$t('Read-only')"
              class="float-left mr-3"
            ></v-switch>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  components: { editor: Editor },
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      tinyId: 0,
    };
  },
  computed: {
    editorLanguage: {
      get: function () {
        if (!this.$i18n.locale()) {
          return "nl";
        }
        return this.$i18n.locale() == "Nl" ? "nl" : "fr_FR";
      },
    },
  },
  watch: {
    editorLanguage: function () {
      this.tinyId += 1;
    },
  },
  methods: {
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
  },
};
</script>
<style scoped>
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>
